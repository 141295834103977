import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Modal from "Common/Components/Modal";
import Select from 'react-select';

import TableContainer from 'Common/TableContainer';
import { Link } from 'react-router-dom';

// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
    getVehiclePlates as onGetVehiclePlates,
} from 'slices/thunk';

interface Option {
    readonly label: string;
    readonly value?: string;
    readonly options?: Option[];
    readonly isDisabled?: boolean
}


const VehiclesDashboard = () => {

    const CitiesOption: Option[] = [
        { label: "Today", value: "1" },
        { label: "Last 7 Days", value: "7" },
        { label: "Last 30 Days", value: "30" },
        { label: "Last 60 Days", value: "60" },
        { label: "Last 90 Days", value: "90" },
    ];

    const LocationsOption: Option[] = [
        { label: "All Locations", value: "all" },
        { label: "Braiven Office", value: "7" },
        { label: "Lumar Office", value: "30" },
    ];

    const VehicleTypesOption: Option[] = [
        { label: "All Types", value: "all" },
        { label: "Car", value: "7" },
        { label: "Truck", value: "30" },
        { label: "Motorcycle", value: "2" },
    ];

    // defaultModal
    const [defaultModal, setDefaultModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<string | null>(null); // Define modalContent state

    const IMAGE_URL = process.env.REACT_APP_API_URL;

    const LocalDateTimeCell = (value: string) => {
        const localDateTime = new Date(value).toLocaleString();
        return <span>{localDateTime}</span>;
    };

    const defaultToggle = (imageUrl: string) => {
        setDefaultModal(!defaultModal);
        setModalContent(imageUrl);
    };

    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.Vehicles,
        (state) => ({
            dataList: state.vehicleList
        })
    );

    const { dataList } = useSelector(selectDataList);
    const [data, setData] = useState<any>([]);

    // Get Data
    useEffect(() => {
        dispatch(onGetVehiclePlates());
    }, [dispatch]);

    useEffect(() => {
        setData(dataList);
    }, [dataList]);

    // columns
    const columns = useMemo(() => [
        {
            header: "Plate Snapshot",
            accessorKey: "platesnapshot",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <Link to="#!" className="flex items-center gap-3" onClick={() => defaultToggle(`${IMAGE_URL}/${cell.row.original.overviewsnapshot}`)}>
                    <div className="shrink-0 bg-slate-100">
                        <img src={`${IMAGE_URL}/${cell.row.original[cell.column.id]}`} alt="" className="h-6" />
                    </div>
                </Link>
            ),
        },
        {
            header: "License Plate	",
            accessorKey: "plate",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <Link to="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">{cell.getValue()}</Link>
            ),
        },
        {
            header: "Province",
            accessorKey: "province",
            enableColumnFilter: false
        },
        {
            header: "Vehicle Type",
            accessorKey: "make",
            enableColumnFilter: false,
        },
        {
            header: "Make & Model",
            accessorKey: "make",
            enableColumnFilter: false,
        },
        {
            header: "Color",
            accessorKey: "color",
            enableColumnFilter: false,
        },
        {
            header: "Location",
            accessorKey: "location",
            enableColumnFilter: false,
        },
        {
            header: "Camera Name",
            accessorKey: "camera",
            enableColumnFilter: false,
        },
        {
            header: "Direction",
            accessorKey: "direction",
            enableColumnFilter: false,
        },
        {
            header: "Date & Time",
            accessorKey: "datetime",
            cell: (cell: any) => (
                LocalDateTimeCell(cell.getValue())
            ),
            enableColumnFilter: false,
        },
    ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Vehicle List' pageTitle='Vehicle' />
            <div className="card" id="plateFilters">
                <div className="card-body">
                    <div className="flex flex-wrap space-x-2 space-y-2 md:space-y-0 md:flex-nowrap">
                        <Select
                            className="w-48 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 custom-select-text-color"
                            id="choices-single-no-sorting"
                            name="choices-single-no-sorting"
                            data-choices data-choices-sorting-false
                            options={CitiesOption}
                            placeholder="Today"
                            styles={{ singleValue: (base) => ({ ...base, color: '#FFF !important' }) }} />
                            <Select
                                className="w-48 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 custom-select-text-color"
                                id="choices-location"
                                name="choices-location"
                                placeholder="All Provinces"
                                data-choices data-choices-sorting-false
                                options={LocationsOption} 
                                styles={{ singleValue: (base) => ({ ...base, color: '#FFF !important' }) }} />
                        <Select
                            className="w-48 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 custom-select-text-color"
                            id="choices-location"
                            name="choices-location"
                            placeholder="All Locations"
                            data-choices data-choices-sorting-false
                            options={LocationsOption} 
                            styles={{ singleValue: (base) => ({ ...base, color: '#FFF !important' }) }} />
                        <Select
                            className="w-48 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            id="choices-multiple-default"
                            data-choices name="choices-multiple-default"
                            isMulti
                            placeholder="All Cameras"
                            options={VehicleTypesOption}
                        />
                        <Select
                            className="w-48 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            id="choices-multiple-default"
                            data-choices name="choices-multiple-default"
                            isMulti
                            placeholder="All Vehicle Types"
                            options={VehicleTypesOption}
                        />
                        <Select
                            className="w-48 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            id="choices-multiple-default"
                            data-choices name="choices-multiple-default"
                            isMulti
                            placeholder="All Colors"
                            options={VehicleTypesOption}
                        />
                    </div>
                </div>
            </div>

            <div className="card" id="plateTable">
                <div className="card-body">
                        <TableContainer
                            isPagination={true}
                            columns={(columns || [])}
                            data={(data || [])}
                            customPageSize={100}
                            isSelect={true}
                            isGlobalFilter={true}
                            divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
                            tableclassName="dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
                            theadclassName="border-b border-slate-200 dark:border-zink-500"
                            tbodyclassName="divide-y divide-slate-200 dark:divide-zink-500"
                            thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 group-[.bordered]:dark:border-zink-500 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left dark:text-zink-50 dark:bg-zink-600 dark:group-[.bordered]:border-zink-500 sorting_asc"
                            tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 group-[.bordered]:dark:border-zink-500"
                            PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                        />
                </div>
            </div>
            <Modal show={defaultModal} onHide={defaultToggle} id="defaultModal" modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full">
                <Modal.Header className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500">
                    <Modal.Title className="text-16">Vehicle Overview Snapshot</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto flex justify-center items-center">
                    {modalContent && <img src={modalContent} alt="Vehicle Overview Snapshot" className="max-w-full max-h-full" />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default VehiclesDashboard;
